import { BrandType, useNotifications } from '@appliedsystems/applied-design-system';
import { ApiError, ApiResponse } from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import { QueryFunctionContext } from '@tanstack/react-query';
import { usePaymentsTranslation } from '../hooks/usePaymentsTranslation';

export const useHttpWrapper = () => {
  const { addNotification } = useNotifications();
  const { t } = usePaymentsTranslation();

  // helper function to actually handle errors
  // onUnhandledError: will be called for any errors besides validation errors.  Use this to display an error message to the user.
  //                      if not passed in, a generic notification toast will be displayed
  //                   if you don't want this to be treated as an error, return a value
  // onValidationError: will be passed any validation errors that are returned from yup
  //                      if not passed in, or throws, the first error message from yup will be displayed in a notificaiton toast
  // onUserFacingError: will be passed any user facing errors that are returned from the API
  //                      if not passed in, or throws, the detail will be displayed in a notification toast
  const handleError = <T>(
    err: ApiError | unknown,
    onUnhandledError?: (err: ApiError | any) => T | void,
    onValidationError?: (error: ApiError) => T | void,
    onUserFacingError?: (error: ApiError) => T | void,
  ) => {
    if (err && typeof err === 'object') {
      if ('name' in err && err.name === 'AbortError') return;
      const apiError = err as ApiError;
      if (
        apiError.type === 'https://api.appliedsystems.com/doc/problem/validation' &&
        (apiError.additionalDetails?.length || apiError.detail)
      ) {
        try {
          if (onValidationError) return onValidationError(apiError);
          else throw new Error('trigger addNotification');
        } catch {
          addNotification({
            type: BrandType.Error,
            content: apiError.additionalDetails?.[0].detail || apiError.detail,
          });
          return;
        }
      }
      if (apiError.type === 'https://api.appliedsystems.com/doc/problem/user-facing-error') {
        try {
          if (onUserFacingError) return onUserFacingError(apiError);
          else throw new Error('trigger addNotification');
        } catch {
          addNotification({
            type: BrandType.Error,
            content: apiError.detail,
          });
          return;
        }
      }
    } else if (typeof err === 'string') {
      addNotification({
        type: BrandType.Error,
        content: err,
      });
      return;
    }

    if (onUnhandledError) return onUnhandledError(err);
    else {
      const traceId = err && typeof err === 'object' && 'traceId' in err ? err.traceId : null;
      const id = traceId ?? datadogLogs.getInternalContext()?.session_id ?? Math.floor(Math.random() * 10000);
      console.error(`Unhandled REST error (id: ${id})`, err);

      addNotification({
        type: BrandType.Error,
        content: id ? t('ERROR_UNKNOWN_WITH_ID') + id : t('ERROR_UNKNOWN'),
      });
    }
  };

  return {
    // helper function for handling all the different possible responses from an Applied Pay API call
    // promise: pass in the promise returned by ApiClient.getInstance().yourRequestHere(..)
    // onSuccess: will be called on an 'ok' response with the data in the body
    // onUnhandledError/onValidationError: see handleError() docs
    wrapRequest: <T>(
      promise: Promise<ApiResponse<T>>,
      onSuccess: (data: T) => void,
      onUnhandledError?: Parameters<typeof handleError>[1],
      onValidationError?: Parameters<typeof handleError>[2],
      onUserFacingError?: Parameters<typeof handleError>[2],
    ): void => {
      void promise
        .then((response) => {
          if (response.status === 'ok') onSuccess(response.data!);
          else handleError(response, onUnhandledError, onValidationError, onUserFacingError);
        })
        .catch(handleError);
    },
    // helper function for handling all the difference responses for an Applied Pay API call when using react-query/useQuery
    // getData: should perform the call to the API
    // onUnhandledError/onValidationError: see handleError() docs
    wrapQuery: <T, U = T>(
      getData: (context: QueryFunctionContext) => Promise<ApiResponse<T>>,
      options?: {
        onUnhandledError?: Parameters<typeof handleError<T>>[1];
        onValidationError?: Parameters<typeof handleError<T>>[2];
        onUserFacingError?: Parameters<typeof handleError<T>>[2];
        transformData?: (data: T) => U;
      },
    ): ((context: QueryFunctionContext) => Promise<U>) => {
      return (context) =>
        getData(context)
          .then((response) => {
            if (response.status === 'ok') return options?.transformData?.(response.data!) ?? (response.data! as U);
            throw response;
          })
          .catch((err) => {
            const res = handleError<T>(
              err,
              options?.onUnhandledError,
              options?.onValidationError,
              options?.onUserFacingError,
            );
            if (typeof res !== 'undefined') return options?.transformData?.(res) ?? (res as any);
            throw err;
          });
    },
    // helper function for handling all the difference responses for an Applied Pay API call when using react-query/useQuery
    // getData: should perform the call to the API
    // onUnhandledError/onValidationError: see handleError() docs
    wrapMutation: <T, U = T, TVariables = void>(
      doMutation: (args: TVariables) => Promise<ApiResponse<T>>,
      options?: {
        onUnhandledError?: Parameters<typeof handleError<T>>[1];
        onValidationError?: Parameters<typeof handleError<T>>[2];
        onUserFacingError?: Parameters<typeof handleError<T>>[2];
        transformData?: (data: T) => U;
      },
    ): ((args: TVariables) => Promise<U>) => {
      return (args) =>
        doMutation(args)
          .then((response) => {
            if (response.status === 'ok') return options?.transformData?.(response.data!) ?? (response.data! as U);
            throw response;
          })
          .catch((err) => {
            const res = handleError<T>(
              err,
              options?.onUnhandledError,
              options?.onValidationError,
              options?.onUserFacingError,
            );
            if (typeof res !== 'undefined') return options?.transformData?.(res) ?? (res as any);
            throw err;
          });
    },
  };
};
